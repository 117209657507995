import { t } from '@lingui/macro';
import type { FunctionComponent } from 'react';
import { supportEmail, supportEmailLink } from '../../shared/config/constants';
import { Typography } from '../../shared/view/components/Typography/Typography';
import { OnboardingTemplateScreen } from './shared/view/OnboardingTemplateScreen';

export const MaintenanceMode: FunctionComponent = () => {
  return (
    <OnboardingTemplateScreen
      title={t`maintenance-mode.title`}
      progressBar={{
        label: t`method.progress-bar`,
        currentStep: 2,
        maxStep: 8,
      }}
    >
      <div className="h-32" />
      <Typography.P2>{t`maintenance-mode.page.title`}</Typography.P2>
      <div className="h-32" />
      <Typography.P2>
        {t`maintenance-mode.page.contact`}
        <a href={supportEmailLink}>{supportEmail}</a>
      </Typography.P2>
    </OnboardingTemplateScreen>
  );
};
